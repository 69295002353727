import { uniqueId } from 'lodash-es';
import { DialogMixin } from '@/mixins/dialog';
import { limitFutureForTimePicker, messageError, translation } from '@/utils';
import { Form } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { SelectMaterial } from '@/views/dialogs';
import { PrepressCraftsService } from '@/api';
import { FieldOption, FieldOptionList } from '@/resource/model';
import { NumberAssignmentTypeEnum, StringAssignmentTypeEnum } from '@/resource/enum';

interface FieldOptions {
  fieldList: Array<FieldOption>;
}

@Component({
  components: { SelectMaterial }
})
export default class BatchSetupData extends mixins(DialogMixin) {
  @Prop({
    type: Array,
    required: true
  })
  public fieldOptions!: Array<FieldOptionList<FieldOption>>;
  public showFieldOptions: Array<FieldOptionList<FieldOption>> = [];

  public title = 'batchSetupData.title';

  public prepressCraftsOptions: Array<{ id: number; label: string; factor: number }> = [];

  public limitDateRangeOption = {
    disabledDate: limitFutureForTimePicker
  };

  public flagOptions: Array<{ label: string; value: string }> = [
    { label: translation('projectProduct.flagOpen'), value: '1' },
    { label: translation('projectProduct.flagClose'), value: '0' }
  ];
  public batchSetuoForm: Partial<FieldOptions> = {
    fieldList: []
  };

  public resourceFormRules = {
    fieldName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('common.select') + translation('batchSetupData.editItem')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    assignmentType: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('common.select') + translation('batchSetupData.editMethod')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    fieldValue: [
      {
        required: true,
        validator: (rule: any, value: string | number | Array<any>, callback: Function): void => {
          const valueType = typeof value;
          console.log(valueType);
          debugger;
          switch (valueType) {
            case 'object':
              if (!value) {
                callback(new Error(translation('common.input') + translation('batchSetupData.editValue')));
                return;
              }
              break;
            // case 'string':
            //   if (!value) {
            //     callback(new Error(translation('common.input') + translation('batchSetupData.editValue')));
            //     return;
            //   }
            //   break;

            case 'number':
              if (!value || value === 0) {
                callback(new Error(translation('common.input') + translation('batchSetupData.editValue')));
                return;
              }
              break;

            default:
              break;
          }

          callback();
        },
        trigger: 'blur'
      }
    ],
    printingCraftsName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('product.selectPrintingCraft')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };
  public submitLoading = false;
  private get addDisabled(): boolean {
    return this.batchSetuoForm.fieldList?.length === this.showFieldOptions.length;
  }
  public dialogOpen(): void {
    this.init();
  }

  public dialogClosed(): void {
    (this.$refs.batchSetuoForm as Form).resetFields();
    this.batchSetuoForm.fieldList = [];
    this.showFieldOptions = [];
  }

  public onSubmit(): void {
    (this.$refs.batchSetuoForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        this.$emit('setData', this.batchSetuoForm.fieldList);
        this.dialogClosed();
      } catch (error) {
        messageError(error);
      } finally {
        // (this.$refs.batchSetuoForm as Form).resetFields();
        this.setLoading(false);
      }
    });
  }

  /**
   * 新增字段
   */
  public handleAddItem(): void {
    this.batchSetuoForm.fieldList?.push(this.defaultFieldData());
    this.disableFields();
  }

  /**
   * 删除字段
   */
  public handleDeleteItem($event: Event, index: number): void {
    this.batchSetuoForm.fieldList?.splice(index, 1);
    this.disableFields();
  }

  /**
   * 设置类型属性赋值
   */
  public setFieldType(field: any): void {
    if (!field.key) {
      return;
    }
    const fieldItem = this.showFieldOptions.filter(x => field.fieldName === x.fieldName)[0];
    field.fieldType = fieldItem.fieldType;
    field.assignmentType = 1;
    field.fieldValue = '';
    this.disableFields();
  }

  /**
   * 下拉输入赋值(后期扩展使用)
   */
  // public changeSetValue(field: FieldOption): void {
  // if (field.fieldValue.length === 0) {
  //   return;
  // }
  // const fieldItem = this.prepressCraftsOptions.filter(x => field.fieldValue.includes(x.id));
  // field.fieldValueItem = fieldItem as any;
  // }

  /**
   * 获取修改方式
   * @param fieldType
   * @returns
   */
  public getAssignmentTypeOption(fieldType: string): Array<{ label: string; value: string }> {
    let options: Array<{ label: string; value: any }> = [];
    switch (fieldType) {
      case 'string':
        options = [
          {
            label: '修改',
            value: StringAssignmentTypeEnum.update
          },
          {
            label: '追加',
            value: StringAssignmentTypeEnum.increase
          }
        ];
        break;
      case 'number':
        options = [
          {
            label: '=',
            value: NumberAssignmentTypeEnum.equal
          },
          {
            label: '+',
            value: NumberAssignmentTypeEnum.add
          },
          {
            label: '-',
            value: NumberAssignmentTypeEnum.sub
          }
        ];
        break;
      default:
        options = [
          {
            label: '修改',
            value: StringAssignmentTypeEnum.update
          }
        ];
    }
    return options;
  }

  /**
   * 禁用已选择字段
   */
  public disableFields(): void {
    const fieldNameList = this.batchSetuoForm.fieldList?.map(item => item.fieldName);
    this.showFieldOptions.forEach(field => {
      if (fieldNameList?.includes(field.fieldName)) {
        field.disabled = true;
      } else {
        field.disabled = false;
      }
    });
  }

  /**
   * 获取印前工艺
   */
  public async getPrepressCraftsOptions(): Promise<void> {
    this.prepressCraftsOptions = [];
    try {
      const res = await PrepressCraftsService.listForComponents({}, { currentPage: 1, showCount: 1000 });
      res.data.forEach(x => {
        this.prepressCraftsOptions.push({ id: x.id!, label: x.name, factor: x.factor });
      });
    } catch (error) {
      messageError(error);
    }
  }

  /**
   * 初始化dialog所需数据
   */
  private async init(): Promise<void> {
    // 判断是否已初始化
    this.showFieldOptions = this.fieldOptions;
    await this.getPrepressCraftsOptions();
    this.setDefault();
  }

  private defaultFieldData(): FieldOption {
    return {
      fieldName: '',
      fieldType: '',
      fieldValue: '',
      fieldValueItem: [],
      assignmentType: undefined,
      key: uniqueId()
    };
  }

  private setDefault(): void {
    this.showFieldOptions.forEach(field => {
      if (field.defaultShow) {
        const fieldOption = {
          fieldName: field.fieldName,
          fieldType: field.fieldType,
          fieldValue: '',
          fieldValueItem: [],
          assignmentType: 1,
          key: uniqueId()
        };
        this.batchSetuoForm.fieldList?.push(fieldOption);
      }
    });

    const defaultShowList = this.showFieldOptions.filter(x => x.defaultShow === true);
    if (defaultShowList.length === 0) {
      this.handleAddItem();
    }
  }
}
